import styled from "styled-components";
import { Link } from "react-router-dom";
import { Highlight } from "./../Header/Header.styles";

export const Heading = styled.div`
  width: 100%;

  font-size: 3em;
  text-align: center;
  font-weight: 900;
  margin: 1.5em 0em 0.5em 0em;
  letter-spacing: 0.1em;
  @media screen and (max-width: 576px) {
    font-size: 2em;
    margin-top: 2em;
    margin-bottom: 0em;
  }
`;
export const WorkContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: auto;
    flex-wrap: wrap;
    padding: 1rem 0;
  }
`;

export const DetailsContainer = styled.div`
  width: 50%;
  padding: 0 4rem;

  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 2.5rem;
  }
`;

export const WorkHeading = styled.h2`
  width: 80%;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: left;
  padding: 1rem 0;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 2rem;
    text-align: center;
  }
`;

export const WorkDesc = styled.p`
  width: 90%;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.3;
    height: 7em;
  }
`;

export const LinkContainer = styled.div`
  width: 80%;
  display: flex;
  padding: 2rem 0;

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
  }
`;

export const KnowMoreButton = styled.a`
  background: #dba8bb;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 80%;
    cursor: pointer;
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.8rem 1.6rem;
  font-weight: bold;
  color: #797878;
  font-size: 1em;

  &:hover {
    cursor: pointer;
    color: #dba8bb;
  }
`;

export const ImagesContainer = styled.div`
  width: 40%;
  height: 85%;
  display: flex;
  overflow-y: hidden;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 0;
    scroll-behavior: smooth;
  }

  @media (max-width: 768px) {
    width: 80%;
    overflow: hidden;
  }
`;

export const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  width: 70%;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Image = styled.img`
  width: ${(props) => (props.plutus ? "75%" : "80%")};
  height: ${(props) => (props.plutus ? "93%" : "90%")};
  border-radius: 0.5em;
  @media (max-width: 768px) {
    height: 25em;
  }
`;

export const HighlightSection = styled.div``;
