import styled from "styled-components";
import { Link } from "react-router-dom";

export const Heading = styled.div`
  width: 100%;
  font-size: 3em;
  text-align: center;
  font-weight: 900;
  margin-top: 2em;
  letter-spacing: 0.1em;
  margin-bottom: 1.5em;

  @media screen and (max-width: 576px) {
    font-size: 2em;
    margin-top: 1em;
  }
`;
export const WorkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    box-sizing: border-box;
  }
`;

export const HalfContainer = styled.div`
  flex: 0.5;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 576px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  flex: 0.6;
  text-align: center;
  padding: 2em;
  font-size: 1.25em;
  letter-spacing: 0.05em;
  @media screen and (max-width: 576px) {
    flex: 0.9;
    padding: 1em 0em 2em 0em;
    line-height: 1.5em;
  }
`;
