import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Hamburger,
  Navbar,
  NavContent,
  NavItem,
  NavItems,
  NavLink,
  NavLogo,
  NavLinkButton,
} from "./Navbar.styles";
import jk from "../../assets/jk.png";

const NavBar = ({ activePage, scrollToContact, scrollToWork }) => {
  const [isNavVisible, setNavVisible] = useState(false);

  return (
    <Navbar>
      <NavLogo to="/">
        <img src={jk} style={{ height: "1.5em" }} />
      </NavLogo>
      <Hamburger
        active={isNavVisible}
        onClick={() => setNavVisible(!isNavVisible)}
      >
        <div></div>
        <div></div>
        <div></div>
      </Hamburger>
      <NavContent
        active={isNavVisible}
        onClick={() => setNavVisible(!isNavVisible)}
      >
        <NavItems>
          {/* <NavItem active={isNavVisible} order={1}>
            <Link to="/" style={{ textDecoration: "none", color: "black" }}>
              <NavLink href="/" active={active === "Home"}>
                Home
              </NavLink>
            </Link>
          </NavItem> */}

          <NavItem active={isNavVisible} order={1}>
            <NavLink href="/" active={activePage === "About"}>
              About
            </NavLink>
          </NavItem>

          <NavItem active={isNavVisible} order={2}>
            <NavLink
              href="/"
              active={activePage === "Portfolio"}
              onClick={scrollToWork}
            >
              Work
            </NavLink>
          </NavItem>

          <NavItem active={isNavVisible} order={3}>
            <NavLinkButton onClick={scrollToContact}>Let's Talk</NavLinkButton>
          </NavItem>
        </NavItems>
      </NavContent>
    </Navbar>
  );
};

export default NavBar;
