import React, { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import {
  DetailsContainer,
  Image,
  ImageDiv,
  ImagesContainer,
  KnowMoreButton,
  LinkContainer,
  StyledLink,
  WorkContainer,
  WorkDesc,
  WorkHeading,
  Heading,
  HighlightSection,
} from "./Work.styles";

export const Work = ({ Projects }) => {
  console.log(Projects);
  const [projects, setProjects] = useState([...Projects]);
  const projectRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    const interval = setInterval(nextProject, 5000);
    return () => clearInterval(interval);
  }, [projects]);

  const nextProject = () => {
    const projectsCopy = [...projects];
    const project = projectsCopy.shift();
    projectsCopy.push(project);
    setProjects(projectsCopy);
  };

  const nextProjectClick = (e) => {
    e.preventDefault();
    nextProject();
  };

  return (
    <div>
      <Heading>See My Work</Heading>
      <WorkContainer ref={containerRef}>
        <DetailsContainer>
          <WorkHeading>{projects[0].heading}</WorkHeading>
          <WorkDesc>{parse(projects[0].desc)}</WorkDesc>
          <LinkContainer>
            <KnowMoreButton href={projects[0].link} target="_blank">
              {projects[0].buttonText}
            </KnowMoreButton>
            <StyledLink onClick={nextProjectClick}>Next &#x25b6;</StyledLink>
          </LinkContainer>
        </DetailsContainer>
        <ImagesContainer ref={projectRef}>
          {projects.map(({ image, heading }, index) => (
            <ImageDiv key={index}>
              <Image
                src={image.src}
                alt={image.alt}
                plutus={heading === "Plutus Software Solutions" ? true : null}
              />
            </ImageDiv>
          ))}
        </ImagesContainer>
      </WorkContainer>
    </div>
  );
};
