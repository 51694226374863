import styled from "styled-components";
import { Link } from "react-router-dom";

export const Heading = styled.div`
  width: 100%;
  font-size: 3em;
  text-align: center;
  font-weight: 900;
  margin-top: 2em;
  letter-spacing: 0.1em;
  margin-bottom: 1.5em;
`;
export const WorkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: auto;
    flex-wrap: wrap;
    padding: 2rem 0;
  }
`;

export const HalfContainer = styled.div`
  flex: 0.5;
  height: 100%;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20em;
`;

export const FeaturedCard = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-image: ${(props) => props.bg};
  border-radius: 1em;
  background-repeat: no-repeat;
  background-position: center;
`;

export const FourCardContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  align-items: center;
  justify-content: space-between;
`;
