import React, { Component } from "react";
import Header from "../../components/Header/Header.component";
import { workData } from "../../common/data/WorkComp";
import { Work } from "../../components/WorkWithCarousel/Work.component";
import { FeaturedSection } from "./../../components/FeaturedSection/FeaturedSection.component";
import { ContactMe } from "../../components/ContactMe/ContactMe.component";
import LetsTalk from "./../../components/LetsTalk/LetsTalk.component";
import Aos from "aos";
import "aos/dist/aos.css";
import HalfHalfDiv from "../../components/HalfHalfDiv/halfhalfdiv.component";
import pic1 from "../../assets/tedx1.jpg";
import pic2 from "../../assets/teaching.jpg";
import pic3 from "../../assets/ResearchThumbnail1.jpg";
import {
  Scholarship,
  Scholarships,
} from "./../../components/Scholarships/Scholarships.component";

class Homepage extends Component {
  constructor(props) {
    super();
    this.contactRef = React.createRef();
    this.workRef = React.createRef();
    this.mlRef = React.createRef();
    this.tedxRef = React.createRef();
    this.educatorRef = React.createRef();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    Aos.init({ duration: 2000 });
  }

  scrollToContact = () => {
    this.contactRef.current.scrollIntoView({ behavior: "smooth" });
  };

  scrollToWork = () => {
    this.workRef.current.scrollIntoView({ behavior: "smooth" });
  };

  scrollToMLResearch = () => {
    this.mlRef.current.scrollIntoView({ behavior: "smooth" });
  };

  scrollToEducator = () => {
    this.educatorRef.current.scrollIntoView({ behavior: "smooth" });
  };

  scrollToTEDxSpeaker = () => {
    this.tedxRef.current.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    return (
      <>
        <Header
          scrollToWork={this.scrollToWork}
          scrollToContact={this.scrollToContact}
          scrollToMLResearch={this.scrollToMLResearch}
          scrollToEducator={this.scrollToEducator}
          scrollToTEDxSpeaker={this.scrollToTEDxSpeaker}
        />
        <div ref={this.tedxRef}>
          <HalfHalfDiv
            order={true}
            pic={pic1}
            title="TEDx Speaker"
            description="I was invited as a TEDx Speaker at Shiv Nadar University, India. I talked about my journey in tech as a woman in the countries I have lived in, combined with my idea of helping young girls to find their passion in tech."
            position="flex-end"
            link="https://www.youtube.com/watch?v=qiyt_jZJWLk&ab_channel=TEDxTalks"
            linkdes="Watch My Talk"
          ></HalfHalfDiv>
        </div>
        <div ref={this.educatorRef}>
          <HalfHalfDiv
            order={false}
            pic={pic2}
            title="Educator"
            description="Founded a registered non-profit that aims to close the gender gap in technology by hosting free coding 
            workshops in native languages for underprivileged girls. Till date, we have taught over 1500 girls. "
            position="flex-start"
            link="https://www.instagram.com/gogirl.code/"
            linkdes="See Go Girl"
          ></HalfHalfDiv>
        </div>
        <div ref={this.mlRef}>
          <HalfHalfDiv
            order={true}
            pic={pic3}
            title="ML Researcher"
            description="Won Yale-NUS award and Merit academic honour at Singapore Science and Engineering Fair 2019.
            Devised a novel landmarker that captured cohesion and separation to facilitate algorithm selection under Dr. Daren Ler, NUS. 
            Published in the Asian Conferance of Machine Learning 2020."
            position="flex-start"
            link="http://proceedings.mlr.press/v129/chen20a.html"
            linkdes="See My Paper"
          ></HalfHalfDiv>
        </div>
        <div data-aos="fade-up">
          <Scholarships />
        </div>

        <div data-aos="fade-up" ref={this.workRef}>
          <Work Projects={workData.Projects} />
        </div>
        <div data-aos="fade-up">
          <FeaturedSection workData={workData} />
        </div>

        <div ref={this.contactRef} data-aos="fade-up">
          <LetsTalk></LetsTalk>
        </div>
      </>
    );
  }
}

export default Homepage;
