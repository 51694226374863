import React, { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { Container, HalfDiv } from "./Scholarships.styles";
import image from "../../assets/speakingBg.png";
import tedx from "../../assets/tedxsg.jpg";
import tedx1 from "../../assets/tedxsnu.png";
import girlkode from "../../assets/girlskode.png";
import tedxngee from "../../assets/tedxngee.jpg";
import rotaract from "../../assets/rotaract.png";
import YoutubeEmbed from "../YoutubeDiv/youtubeDiv";
import { Heading } from "../FeaturedSection/FeaturedSection.styles";
import adobe from "../../assets/adobelogo.png";
import sg from "../../assets/singaporeministry.png";
import zonta from "../../assets/zonta_small.jpg";
import otu from "../../assets/Ontariotechlogo.jpg";
import cadence from "../../assets/cadence_small.jpg";

export const Scholarships = () => {
  return (
    <div>
      <Container>
        <Heading>Scholarships Won</Heading>
        <HalfDiv>
          <img src={sg} style={{ width: "170px" }} data-aos="zoom-in" />
          <a
            href="https://news.ontariotechu.ca/archives/2020/08/presenting-ontario-tech-universitys-2020-2021-major-scholarship-recipients.php"
            target="_blank"
          >
            <img src={otu} style={{ width: "200px" }} data-aos="zoom-in" />
          </a>
          <a
            href="https://research.adobe.com/scholarship/previous-scholarship-award-winners/"
            target="_blank"
          >
            <img src={adobe} style={{ width: "200px" }} data-aos="zoom-in" />
          </a>
          <a
            href="https://www.credly.com/badges/6210c81f-4471-42ea-98b7-75fb8fb78a2c"
            target="_blank"
          >
            <img src={cadence} style={{ width: "200px" }} data-aos="zoom-in" />
          </a>

          <img src={zonta} style={{ width: "170px" }} data-aos="zoom-in" />
        </HalfDiv>
      </Container>
    </div>
  );
};
