import styled from "styled-components";

export const HeaderImageDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 4em;
  justify-content: center;
  height: 80vh;
  @media screen and (max-width: 576px) {
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-bottom: 6em;
  }
`;

export const CenterPieceDiv = styled.div`
  flex: 0.45;
  height: 60vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1024px) {
    width: ${(props) => props.ipadwidth};
  }
  @media screen and (max-width: 576px) {
    flex: 0.9;
    width: ${(props) => props.mobwidth};
    position: static;
    height: 60vh;
  }
`;

export const HeaderImage = styled.img`
  width: ${(props) => props.width};

  z-index: ${(props) => props.zindex};

  @media screen and (max-width: 1024px) {
    width: ${(props) => props.mobwidth};
    position: static;
  }
`;

export const HeaderText = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width: 1024px) {
    text-align: center;
  }
`;

export const HeaderGreeting = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 3.5em;

  @media screen and (max-width: 1024px) {
    font-size: 3em;
    text-align: center;
    padding: 0.25em;
  }
`;

export const AboutMeDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 576px) {
    margin-top: 0.5em;
  }
`;

// export const BlackLineDiv = styled.div`
//   flex: 0.1;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   @media screen and (max-width: 576px) {
//     visibility: hidden;
//   }
// `;

// export const BlackLine = styled.div`
//   width: 100%;
//   height: 0.15em;
//   background-color: black;
//   @media screen and (max-width: 576px) {
//     visibility: hidden;
//   }
// `;

export const Description = styled.div`
  flex: 0.8;
  text-align: center;
  padding: 2em;
  font-size: 1em;
  letter-spacing: 0.05em;
  @media screen and (max-width: 1024px) {
    flex: 0.9;
    padding: 1em;
    line-height: 1.5em;
  }
  @media screen and (max-width: 576px) {
    flex: 0.9;
    padding: 0em 0.5em 0.5em 0.5em;
    line-height: 1.5em;
  }
`;

export const SocialsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SocialImage = styled.img`
  width: 2.5em;
  height: 2.5em;
  margin: 0em 0.5em;
`;

export const Highlight = styled.a`
  text-decoration: underline;
  color: black;
  &:hover {
    opacity: 90%;
  }
`;

export const DownloadLink = styled.a`
  color: black;
  text-decoration: none;
  font-size: 1em;
  margin: 1em 0em;
  &:hover {
    opacity: 90%;
    cursor: pointer;
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 1em;
    margin-top: 0em;
  }
`;

export const PinkButton = styled.a`
  background: #dba8bb;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.85em;
  padding: 0.5em 1.5em;
  margin: 0em 1em;
  border-radius: 25px;
  font-weight: bold;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 80%;
    cursor: pointer;
  }
  @media screen and (max-width: 1024px) {
    font-size: 0.75em;
    text-align: center;
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
`;
