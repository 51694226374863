import React, { Component } from "react";
import NavBar from "../Navbar/Navbar.component";
import me from "../../assets/Header1.png";
import bg from "../../assets/Header2.png";
import headerimg from "../../assets/headerimage.jpg";
import circle from "../../assets/Header3.png";
import pinkRect from "../../assets/pinkRectangle.png";
import greyRect from "../../assets/greyRectangle.png";
import {
  HeaderImageDiv,
  HeaderImage,
  CenterPieceDiv,
  HeaderText,
  AboutMeDiv,
  HeaderGreeting,
  BlackLine,
  Description,
  BlackLineDiv,
  SocialsDiv,
  SocialImage,
  Highlight,
  DownloadLink,
  PinkButton,
} from "./Header.styles";
import insta from "../../assets/pinkInsta.png";
import git from "../../assets/gitpink.png";
import linkedin from "../../assets/linkedingrey.png";
import resume from "../../assets/Latest Resume.pdf";

class Header extends Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div>
        <div>
          <NavBar
            activePage="About"
            scrollToWork={this.props.scrollToWork}
            scrollToContact={this.props.scrollToContact}
          />
        </div>
        <HeaderImageDiv>
          <CenterPieceDiv data-aos="fade-zoom-in">
            <HeaderImage
              src={headerimg}
              zindex="1"
              width="100%"
              mobwidth="80%"
              ipadwidth="50%"
              grey={true}
            ></HeaderImage>
          </CenterPieceDiv>
          <HeaderText>
            <div data-aos="zoom-in-up">
              <HeaderGreeting>Hi, I am Japnit!</HeaderGreeting>
            </div>
            <div data-aos="fade-up" data-aos-delay="400">
              <AboutMeDiv>
                <Description>
                  I am a self motivated third year computer science student with
                  over five years of core programming experience.{" "}
                  <em>Learn more about me</em>!
                </Description>
              </AboutMeDiv>
            </div>
            <div data-aos="fade-up" data-aos-delay="800">
              <SocialsDiv>
                <PinkButton onClick={this.props.scrollToTEDxSpeaker}>
                  TEDx Speaker
                </PinkButton>
                <PinkButton onClick={this.props.scrollToEducator}>
                  Educator
                </PinkButton>
                <PinkButton onClick={this.props.scrollToMLResearch}>
                  ML Researcher
                </PinkButton>
              </SocialsDiv>
            </div>
            <div data-aos="fade-up" data-aos-delay="1200">
              <AboutMeDiv>
                <DownloadLink href={resume} download>
                  <b>Download my resume.</b>
                </DownloadLink>
              </AboutMeDiv>
              <SocialsDiv>
                <a
                  href="https://www.instagram.com/japnitahuja/"
                  target="_blank"
                >
                  <SocialImage src={insta}></SocialImage>
                </a>
                <a
                  href="https://www.linkedin.com/in/japnitahuja/"
                  target="_blank"
                >
                  <SocialImage src={linkedin}></SocialImage>
                </a>

                <a href="https://github.com/japnitahuja" target="_blank">
                  <SocialImage src={git}></SocialImage>
                </a>
              </SocialsDiv>
            </div>
          </HeaderText>
        </HeaderImageDiv>
      </div>
    );
  }
}

export default Header;
