import React, { Component } from "react";
import NavBar from "../Navbar/Navbar.component";
import me from "../../assets/Header1.png";
import bg from "../../assets/Header2.png";
import circle from "../../assets/Header3.png";
import pinkRect from "../../assets/pinkRectangle.png";
import greyRect from "../../assets/greyRectangle.png";
import {
  HeaderImageDiv,
  HeaderImage,
  CenterPieceDiv,
  HeaderText,
  AboutMeDiv,
  BlackLine,
  Description,
  BlackLineDiv,
  SocialsDiv,
  SocialImage,
  Highlight,
  Heading,
  Footer,
} from "./LetsTalk.styles";
import insta from "../../assets/pinkInsta.png";
import git from "../../assets/gitpink.png";
import linkedin from "../../assets/linkedingrey.png";

class LetsTalk extends Component {
  render() {
    return (
      <div>
        <Heading>Let's Talk</Heading>
        <AboutMeDiv>
          <BlackLineDiv>
            <BlackLine />
          </BlackLineDiv>
          <Description>
            If you have any questions about my work or you want to collaborate
            with me or if you just want to just connect, feel free to email me
            at <em>japnit.ahuja@gmail.com</em>!
            <br />
            <br />
            <b>Looking forward to hearing from you!</b>
          </Description>
          <BlackLineDiv>
            <BlackLine />
          </BlackLineDiv>
        </AboutMeDiv>

        <SocialsDiv>
          <a href="https://www.instagram.com/japnitahuja/" target="_blank">
            <SocialImage src={insta}></SocialImage>
          </a>
          <a href="https://www.linkedin.com/in/japnitahuja/" target="_blank">
            <SocialImage src={linkedin}></SocialImage>
          </a>

          <a href="https://github.com/japnitahuja" target="_blank">
            <SocialImage src={git}></SocialImage>
          </a>
        </SocialsDiv>
        <Footer>Made with {"<loadsoflove/>"} by Japnit Kaur Ahuja</Footer>
      </div>
    );
  }
}

export default LetsTalk;
