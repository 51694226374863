import styled from "styled-components";

export const Heading = styled.div`
  width: 100%;
  font-size: 3em;
  text-align: center;
  font-weight: 900;
  margin-top: 2em;
  letter-spacing: 0.1em;
  @media screen and (max-width: 576px) {
    font-size: 2em;
    margin-top: 1em;
  }
`;

export const HeaderImageDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const AboutMeDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  @media screen and (max-width: 576px) {
    margin-top: 0.5em;
  }
`;

export const BlackLineDiv = styled.div`
  flex: 0.1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 576px) {
    visibility: hidden;
  }
`;

export const BlackLine = styled.div`
  width: 100%;
  height: 0.15em;
  background-color: black;
  @media screen and (max-width: 576px) {
    visibility: hidden;
  }
`;

export const Description = styled.div`
  flex: 0.6;
  text-align: center;
  padding: 2em;
  font-size: 1.25em;
  letter-spacing: 0.05em;
  @media screen and (max-width: 576px) {
    font-size: 1.25em;
    padding: 1em;
    flex: 1;
  }
`;

export const SocialsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
`;

export const SocialImage = styled.img`
  width: 2.5em;
  height: 2.5em;
  margin: 0.5em;
`;

export const Highlight = styled.span`
  text-decoration: underline;
`;

export const Footer = styled.div`
  width: 100%;
  font-size: 1em;
  letter-spacing: 0.2em;
  padding: 0.75em;
  box-sizing: border-box;
  color: whitesmoke;
  background-color: #dba8bb;
  text-align: center;
`;
