import img1 from "../work-images/Img1.png";
import img2 from "../work-images/Img2.png";
import img3 from "../work-images/Img3.png";
import img4 from "../work-images/Img4.png";
import ssefResearch from "../../assets/ResearchThumbnail3.jpg";
import ACML from "../../assets/ACMLThumbnail.png";
import tgc from "../../assets/TGCThumbnail5.jpg";
import plutus from "../../assets/plutusThumbnail.png";
import paper from "../../assets/SSEFReport.pdf";

export const workData = {
  Projects: [
    {
      heading: "Published ML Research in ACML'20",
      desc: "Devised a novel landmarker that captured cohesion and separation at an instance \
      level to facilitate algorithm selection under Dr. Daren Ler, NUS. <em> Published in \
      the Asian Conferance of Machine Learning 2020.</em>",
      image: { src: ACML, alt: "SSEF Research" },
      link: "http://proceedings.mlr.press/v129/chen20a.html",
      buttonText: "See Paper",
    },
    {
      heading: "Singapore Science and Engineering Fair 2019",
      desc: "Constructed a novel two-layered algorithm selection framework in meta-learning. \
      <em>Won Yale-NUS award and Merit academic honour at Singapore Science and Engineering Fair 2019.</em>",
      image: { src: ssefResearch, alt: "SSEF Research" },
      link: paper,
      buttonText: "See Report",
    },
    {
      heading: "Girlcode Humanitarian Foundation",
      desc: "Founded a registered non-profit that aims to close the gender gap in \
      technology by hosting free coding workshops in native languages for underprivileged girls.\
      <em>Till date, we have taught over 1500 girls.</em>",
      image: { src: tgc, alt: "SSEF Research" },
      link: "http://thegirlcode.co/",
      buttonText: "See Website",
    },
    {
      heading: "Plutus Software Solutions",
      desc: "Launched a startup to help businesses develop a digital presence by providing them with software solutions. \
      <em>Deployed  websites in MERN stack for companies spanning various industries.</em>",
      image: { src: plutus, alt: "Plutus Software Solutions" },
      link: "https://plutustech.org/",
      buttonText: "See Website",
    },
  ],
};
