import React, { Component } from "react";
import NavBar from "../Navbar/Navbar.component";
import me from "../../assets/Header1.png";
import bg from "../../assets/Header2.png";
import circle from "../../assets/Header3.png";
import pinkRect from "../../assets/pinkRectangle.png";
import greyRect from "../../assets/greyRectangle.png";
import {
  HeaderImageDiv,
  HeaderImage,
  CenterPieceDiv,
  HeaderText,
  AboutMeDiv,
  HeaderGreeting,
  BlackLine,
  Description,
  BlackLineDiv,
  SocialsDiv,
  SocialImage,
  Highlight,
  DownloadLink,
  PinkButton,
} from "./halfhalfdiv.styles";
import insta from "../../assets/pinkInsta.png";
import git from "../../assets/gitpink.png";
import linkedin from "../../assets/linkedingrey.png";
import resume from "../../assets/Latest Resume.pdf";
import pic1 from "../../assets/tedx1.jpg";
import pic2 from "../../assets/teaching.jpg";

class HalfHalfDiv extends Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div>
        <HeaderImageDiv order={this.props.order}>
          <CenterPieceDiv
            align={this.props.position}
            data-aos={this.props.order ? "fade-right" : "fade-left"}
          >
            <HeaderImage
              src={this.props.pic}
              zindex="1"
              width="100%"
              mobwidth="80%"
              grey={true}
            ></HeaderImage>
          </CenterPieceDiv>
          <HeaderText data-aos={this.props.order ? "fade-left" : "fade-right"}>
            <HeaderGreeting>{this.props.title}</HeaderGreeting>
            <AboutMeDiv>
              <Description>
                {this.props.description}
                <br></br>
                <br></br>
                <PinkButton href={this.props.link} target="_blank">
                  {this.props.linkdes}
                </PinkButton>
              </Description>
            </AboutMeDiv>
          </HeaderText>
        </HeaderImageDiv>
      </div>
    );
  }
}

export default HalfHalfDiv;
