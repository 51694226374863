import React, { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import {
  WorkContainer,
  Heading,
  HalfContainer,
  FeaturedCard,
  FourCardContainer,
  Description,
} from "./FeaturedSection.styles";
import image from "../../assets/speakingBg.png";
import tedx from "../../assets/tedxsg.jpg";
import tedx1 from "../../assets/tedxsnu.png";
import girlkode from "../../assets/girlskode.png";
import tedxngee from "../../assets/tedxngee.jpg";
import rotaract from "../../assets/rotaract.png";
import YoutubeEmbed from "../YoutubeDiv/youtubeDiv";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export const FeaturedSection = ({ workData }) => {
  const { height, width } = useWindowDimensions();
  console.log(width, width / 3);
  let { vidheight, vidwidth } = 0;
  if (width > 768) {
    vidwidth = width / 2.5;
    vidheight = width / 4;
  } else {
    vidwidth = width - 50;
    vidheight = width / 2;
  }
  return (
    <div>
      <Heading>Watch Me Speak</Heading>
      <WorkContainer>
        <HalfContainer>
          <YoutubeEmbed
            embedId="JYzJhHQDm2s"
            height={`${vidheight}px`}
            width={`${vidwidth}px`}
          />
          <Description>
            I was invited to speak at{" "}
            <em>TEDx Ngee Ann Polytechnic, Singapore </em>
            on the theme of The Road Not Taken.
          </Description>
        </HalfContainer>
        <HalfContainer>
          <YoutubeEmbed
            embedId="xwYA_-Uswwc"
            height={`${vidheight}px`}
            width={`${vidwidth}px`}
          />
          <Description>
            I was invited to for an interview by{" "}
            <em>ACM-W, Vellore Institute of Technology </em>
            on my journey in tech.
          </Description>
        </HalfContainer>
      </WorkContainer>
    </div>
  );
};
